//Normal M Interview
import styles from './Nicole.module.css';
import React from 'react';
import ReturnToTop from './ReturnToTop';
import Fade from 'react-reveal/Fade';

class NicoleM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            igPosts: [],
            hovered: [false,false,false,false]
        }
    }

    async componentDidMount() {
        const urls = [
            "https://www.instagram.com/p/BxFdg26lgrS",
            "https://www.instagram.com/p/Bwmx3ctFA0S",
            "https://www.instagram.com/p/Bvt2rJFFwzV",
            "https://www.instagram.com/p/Bv9ekwAlwMs"
        ];
        getIgPosts(urls)
            .then(imgs => {
                this.setState({igPosts: imgs})
            })
    }


    toggleImage = (index) => {
        if (window.innerWidth > 768) {
            let hovered = this.state.hovered;
            hovered[index] = !hovered[index];
            this.setState({hovered});
        }
    }

    mobileClick = (event,index) => {
        if (window.innerWidth > 768) return;
        let hovered = this.state.hovered;
        if (!hovered[index]) {
            event.preventDefault();
            hovered = hovered.map( (d) => {return false});
            hovered[index] = true;
            this.setState({hovered})
            return;
        }
        hovered[index] = false;
        this.setState({hovered});
    }

    resetHover = (e) => {
        const clickedTag = e.target.tagName.toLowerCase();
        if (window.innerWidth > 768 || clickedTag === "a") return;
        const baseHover =  [false,false,false,false];
        this.setState({hovered:baseHover});
    }


    render() {
        return (
            <div className={styles.interview} onClick={this.resetHover}>
                <img className={styles.hero} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-1.jpeg?3610" alt="Nicole McLaughlin Hero"/>
                <div className={styles.headers}>
                    <h1>Nicole McLaughlin's Intuitive Upcycling</h1>
                    <h2>The designer will share the process behind her inventively rebuilt footwear and apparel at ISPA Sprint One</h2>
                </div>
                <div className={styles.content}>
                    <div className={styles.block1}>
                        <div className={styles.text}>
                            <p>It’s hard to imagine a designer whose process and output more fully embodies the ethos of the ISPA program than Nicole McLaughlin’s. Her work—which blew up in a big way when she began sharing it regularly on Instagram in late 2018—is powered by resourceful adaptation. The objects she creates manifest as footwear and clothing. Sometimes she turns one apparel typology into another—building pants out of ski vests, for example—and others she completely reimagines her source material’s form and function, transforming sports equipment, consumer electronics, and more into wearable gear.  Whether it’s tailoring a functional button-down out of Dover Street Market tissue paper—one of her early projects—to fashioning a slide out of a Capri Sun juice pouch, her approach is one of whimsical improvisation. It’s this element of surprise, paired with the delight in discovering just what these objects are capable of becoming, that makes her work so compelling.</p>
                            
                            <p>Nicole’s work also connects with its audience because it is, by nature, sustainable. Hand-made from materials scavenged from thrift stores and eBay, she’s developed a circular process the celebrates reuse.</p>
                            
                            <p style={{marginBottom: "24px"}}>This Sunday, she’ll lead a workshop at Notre in which she’ll share her workflow with participants, leading them through the process of building a piece of footwear from the same sorts of recycled materials she uses for her own practice. Notre linked up with Nicole in New York City to chat about her creative journey and what she has planned for this weekend—and to watch her build one of her pieces from start to finish. </p>
                            
                            <div className={styles.twos}>
                                <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-2.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                                <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-3.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            </div>
                            
                            <p><b style={{color:"#71994C"}}>ADAM WRAY</b>: ISPA stands for Improvise, Scavenge, Protect, and Adapt. Do you identify with this orientation towards design at all?</p>
                            
                            <p><b>NICOLE MCLAUGHLIN</b>: I think that's actually a really good way of putting what I do. Improvisation is a huge part of my process because it's very much intuition-based. I don't come from a traditional design background, so anytime I start a project, it's always a brand new experience for me.</p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: So, when you're searching for new ideas, do you go out looking for something specific? Or are you just letting the materials you find speak to you and dictate the project that way?</p>
                            
                            <p><b>NM</b>: While I'm looking for products, thrifting, I might have a vision of what it's going to be, but then once I actually start creating, it starts to evolve. Thrifting is one of the biggest parts of my process—I’m definitely a scavenger in these situations. The best part about this process is relying so much on secondhand goods, because there's so much beauty that's already in those things. A lot of the time, the fabric and the material dictate whatever it is. I made&nbsp;
                                 <HoverLink {...this.state.igPosts[0]}
                                    toggleImage={() => this.toggleImage(0)}
                                    mobileClick={(e) => this.mobileClick(e,0)}
                                    hovered={this.state.hovered[0]}
                                    styleOverride={{top:"50px"}}
                                 >pants out of these shoe organizers
                                 </HoverLink>
                                —their texture was like a linen, so, immediately, I thought of pants. </p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: Those were so cool, because the fabric is soft, but they reminded me of samurai armor. The overlapping tiles.</p>
                            
                            <p><b>NM</b>: I love hearing what people think of when they see these, because everyone is so different. I have a vision of what I think it is, but it could be interpreted so many different ways. It’s cool being able to provoke those ideas. Provoking nostalgia is a big part of my work—for people to feel some type of way about their childhood, or something that feels familiar to them that they hadn't thought about in a while. </p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: Could you give me an example of something you've made that you thought did that nostalgia piece well?</p>
                            
                            <p><b>NM</b>: I did a shoe out of&nbsp;
                                <HoverLink {...this.state.igPosts[1]}
                                    toggleImage={() => this.toggleImage(1)}
                                    mobileClick={(e) => this.mobileClick(e,1)}
                                    hovered={this.state.hovered[1]}
                                >Haribo gummi bears</HoverLink>
                                . That was a childhood memory. And the&nbsp;
                                <HoverLink {...this.state.igPosts[2]}
                                    toggleImage={() => this.toggleImage(2)}
                                    mobileClick={(e) => this.mobileClick(e,2)}    
                                    hovered={this.state.hovered[2]}
                                    styleOverride={{top:"20px"}}     
                                >velcro toss game</HoverLink>
                                —my sister and I would always play that when we went to the beach when we were little. So, simple things like that. But, for me, a lot of the nostalgia comes from outdoor brands. As a child, I would always be wearing those&nbsp;
                                <HoverLink {...this.state.igPosts[3]}
                                    toggleImage={() => this.toggleImage(3)}
                                    mobileClick={(e) => this.mobileClick(e,3)}
                                    hovered={this.state.hovered[3]}
                                    styleOverride={{top:"50px"}}
                                >puffy ski jackets</HoverLink>
                                &nbsp;that my mom would put me in. I love those, even still today, I get really inspired by outdoor brands. </p>
                            
                            <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-4.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: You spent a lot of time outside growing up?</p>
                            
                            <p><b>NM</b>: Definitely. I'm from the suburbs, but I was always outdoors as a kid. I skied and I snowboarded when I was little and I still snowboard now. I always was playing sports—basketball, tennis, ice skating, all that, and I rock climb now. </p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: Does climbing interface with your creative work in any way?</p>
                            
                            <p><b>NM</b>: Yeah, for sure. With rock climbing, functionality is something that I've really thought a lot more about. I'm also a runner, and with running, you just need good shoes and clothes that aren't going to bother you. With rock climbing, the pants that you wear, the chalk bag, the shoes that you wear—everything is playing a part, whether you're thinking about it or not. Once I started discovering more about that sport, and how to get the most out of what you're wearing, something about that became really interesting. The clothes are actually really cool, and it's not like they're trying to be.</p>
                            
                            <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-5.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: That sort of leads back to another of the ISPA principles, Protect.</p>
                            
                            <p><b>NM</b>: Protect is an interesting one for me because, in terms of social media, I was very protective of my work, and I didn't show it for a while. I was so nervous about people taking my ideas, and I was overly protective. Now it's more about letting go a bit, and helping other people get involved in it.</p>
                        </div>
                        <div className={styles.images}>
                            <div className={styles.twos}>
                                <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-2.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                                <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-3.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            </div>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-4.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-5.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        </div>
                    </div>
                    <div className={styles.block2}>
                        <h5>
                        "The best part about this process is relying so much on secondhand goods, because there's so much beauty that's already in those things."
                        </h5>
                        <div className={styles.threes}>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-6.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-7.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-8.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        </div>
                        <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-6.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-7.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-8.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                    </div>
                    <div className={styles.block1} style={{marginTop: "80px"}}>
                        <div className={styles.text}>
                            <p><b style={{color:"#71994C"}}>AW</b>: Can you tell me more about getting other people involved? How does that actually play out in your work?</p>
                            
                            <p><b>NM</b>: It took me a while to really be able to express the things that I'm doing, to be able to get those ideas out. A lot of the time, people don't start creative endeavors because they don't know how to. With sewing, or construction, people just don't know the fundamentals of those things. Once I figured out what worked best for me, I wanted to help people find their own practice, and shed some light on the stuff that I've learned by doing. It's not a very conventional process, and you'll see that firsthand at this workshop. It's a lot more sculpting than it is traditional shoemaking or apparel-making. People are so used to thinking about things in a traditional way, that once I broke that boundary, I was like, "I need to tell someone about this, I need to help someone do the same thing.”</p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: Have you always had this drive to dig, and research, and self-teach?</p>
                            
                            <p><b>NM</b>: Yeah. I wasn't a good student. I had a really difficult time in school, just staying focused. I just found my own way of learning things, and being able to research things on my own. I tended to just want to learn more about the stuff that I found interesting, which was not always great when I was trying to learn math and science in school. In high school, I taught myself sign language, because I had a boyfriend who was deaf. That was such a cool part of my life, because I started realizing at that point—I was, like, 15—that there was something about being self-taught that I liked, learning things I wouldn’t learn in traditional settings.</p>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: On that note, could you tell me about what you have planned for the workshop this weekend, and how you approach these sorts of teaching situations in general?</p>
                            
                            <p><b>NM</b>: I’ll be explaining a little bit more about my process, because I think once you understand how these things are done and how I approach them, it's easier to start it yourself. I'll be next to you on the floor, we're figuring it out together. It's going to be teaching people to use their intuition, and to rely on these fabrics to take a natural shape. Like I said, it’s sculpting—you go with what the fabric does. How do you highlight those existing shapes?</p>
                            
                            <p>Also, it’s teaching people how to cut things in a way so you can save those scraps,  and be able to maximize as much as you can so that you have, in theory, zero waste. It’s having people think a little bit more about the stuff that they have in their own closet. Instead of just throwing it out, maybe they can do this stuff when they get home.</p>
                            
                            <div className={styles.twos}>
                                <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-9.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                                <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-10.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            </div>
                            
                            <p><b style={{color:"#71994C"}}>AW</b>: It sounds like you sort improvised your way into a sustainable practice. Have you always been interested in these types of design strategies?</p>
                            
                            <p><b>NM</b>: The sustainability part of it has become a huge part of my ethos. I was going to thrift stores and finding secondhand stuff because it was a cheaper option for me to learn how to do these things, but eventually I was like, "Oh my gosh, I'm actually being sustainable, and I'm giving a product a new life."</p>
                            
                            <p>I realized the beauty in that, but at the time I was also working at a big brand, seeing both sides of it—hand-making these things out of old stuff while working for a brand that's making mass amounts of product. How can I instill the ethos that I've built for myself into a larger company? So, it's a lot of sharing the information that I've found, bringing that into bigger settings to help them think about their own products in a new way.</p>
                            
                            <p>Something I actually talk a lot about, for younger kids that are just getting into fashion—because so many people are starting brands nowadays—is that if you're going to start a brand, great, but start it sustainably. Start it from the beginning. Because you never know—if you blow up really big one day, you don't want to have to go back and change your whole model of how things are being made, so it's just an easier start that way. And it's the future. We have to start right now.</p>
                            
                            <Fade bottom distance="50px"><img className={styles.mobile} src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-12.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        </div>
                        <div className={styles.images}>
                            <div className={styles.twos}>
                                <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-9.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                                <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-10.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                            </div>
                            <Fade bottom distance="50px"><img src="https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-12.jpeg?3610" alt="Nicole McLaughlin"/></Fade>
                        </div>
                    </div>
                </div>
                <ReturnToTop />
            </div>
            )
    }
}

const HoverLink = (props)=> {

    const text = props.children;
    const postUrl = props.postUrl;
    const imgUrl = props.imgUrl;
    return (
        <div style={{display:"inline"}}>
        <a href={postUrl} onMouseEnter={props.toggleImage} onMouseLeave={props.toggleImage} onClick={(e) => props.mobileClick(e)}>
            {text}
        </a>
        {props.hovered && <img style={props.styleOverride} className={styles.hover} src={imgUrl} alt={text + "Instagram Post"}/>}
        </div>
    )
}

const getIgPosts = async function(urls) {

    const promises = urls.map((url) => fetch(url+"/media/?size=m"));
    const imgs = await Promise.all(promises)
        .then(res => {
            let ret = [];
            res.forEach((res,i) => { 
                ret.push( {
                    postUrl: urls[i],
                    imgUrl: res.url
                })
            });
            return ret;
        });

    return imgs;
}

export default NicoleM;