import React from 'react';
import { ReactComponent as Arrow } from '../assets/curvedarrow.svg';
import styles from './ReturnToTop.module.css';

const ReturnToTop = () => {
    const toTop = () => {
        setTimeout(function() {
            const el = document.getElementsByTagName("header")[0];
            el.scrollIntoView({ behavior: 'smooth' , block: 'nearest', inline: 'center'});
        },100); 
    }
    
    return (
        <div onClick={() => toTop()}
            className={styles.returnToTop}>
            <span>RETURN TO TOP</span>
            <Arrow />
        </div>
    )
}

export default ReturnToTop;