import React from 'react';
import styles from './Nav.module.css';
import { ReactComponent as Arrow } from '../assets/curvedarrow.svg';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render () {
        const navs = [
            {
            date:"",
            hoverImage: "https://cdn.shopify.com/s/files/1/0624/0605/files/ISPAShoesSchematics.jpg?3018",
            disabled: false,
            leftImagePlacement: true,
            navId: "about",
            text: "ABOUT THE WORKSHOPS"
            },
            {
            date: "CLOSED",
            hoverImage: "https://cdn.shopify.com/s/files/1/0624/0605/files/notre-norman-kelley-interior-chicago-us_dezeen_2364_col_8-852x639.jpg?3001",
            disabled: true,
            navId: "sign-up",
            text: "SIGN UP FOR THE WORKSHOPS"
            },
            {
            date: "LIVE",
            hoverImage: "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-9.jpeg?3028",
            disabled: false,
            leftImagePlacement: true,
            navId: "norman-kelley",
            text: "INTERVIEW: NORMAN KELLEY"
            },   
            {
            date: "LIVE",
            hoverImage: "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nm-1.jpeg?3610",
            disabled: false,
            navId: "nicole",
            text: "INTERVIEW: NICOLE MCLAUGHLIN"
            },
            {
            date: "LIVE",
            hoverImage: "https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-34-nk.jpg?3630",
            disabled: false,
            leftImagePlacement: true,
            navId: "recap",
            text: "RECAP AND DOCUMENTATION"
            }
        ];

        const navButtons = navs.map((opts) => {
            return <NavButton updateSelected={this.props.updateSelected} {...opts} key={opts.navId}>{opts.text}</NavButton>
        });

        return (
            <header className={styles.header}>
                <div className={styles.header__logos}>
                    <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/Notre_Logo.png?2998" alt="Notre Logo"/>
                    <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_SprintOne_Logo_v2.png?3005" alt="ISPA Logo"/>
                </div>
                <ul className={styles.header__nav}>
                    {navButtons}
                </ul>
            </header>
        )
    }
}
class NavButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        }
    }

    toggleImage = () => {
        if (window.innerWidth > 768) this.setState({hovered: !this.state.hovered});
    }

    selectContent = (id) => {
        if (!this.props.disabled) {
            this.props.updateSelected(id);
        }
    }
    render() {
        const navId = this.props.navId
        const imagePlacement = this.props.leftImagePlacement ? styles.leftImagePlacement : '';
        const disabledImage = this.props.disabled ? styles.disabledImage : '';
        let overrideStyle = {}; 
        if (this.props.navId === 'nicole' ) {
            overrideStyle = {top: -15+"em"}
        } else if (this.props.navId === 'recap') {
            overrideStyle = {top: -20+"em"}
        }
        
        if (navId !== "sign-up") {
            return (
                <li onClick={() => this.selectContent(navId)} onMouseEnter={this.toggleImage} onMouseLeave={this.toggleImage}>
                    <span>{this.props.date}</span>
                    <h1 className={this.props.disabled ? styles.disabled : ''}>
                        {this.props.children}
                        {!this.props.disabled && <Arrow />}
                    </h1>
                    { this.state.hovered &&
                        <img src={this.props.hoverImage} 
                        className={`${imagePlacement} ${disabledImage}`}
                        style={overrideStyle}
                        alt=""/>
                    }
                </li>
            )
        } else {
            return (
                <li onMouseEnter={this.toggleImage} onMouseLeave={this.toggleImage}>
                    <span>{this.props.date}</span>
                    <a href="https://forms.gle/BwxETBkvcoEDkiAx7" target="_blank" title="Sign Up Form" rel="noopener noreferrer" style={{pointerEvents: "none"}}>
                        <h1 className={this.props.disabled ? styles.disabled : ''}>
                            {this.props.children}
                        </h1>
                    </a>
                    { this.state.hovered &&
                        <img src={this.props.hoverImage} 
                        className={`${imagePlacement} ${disabledImage}`}
                        alt=""/>
                    }
                </li>
            )
        }
    }
}

export default Nav;