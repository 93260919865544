import React from 'react'
import NicoleM from './Nicole';
import Recap from './Recap.jsx';
import ReturnToTop from './ReturnToTop';
import styles from './Interview.module.css';
import Fade from 'react-reveal/Fade';

class Interview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    
    componentDidMount() {
        this.scrollToContent();
    }

    componentDidUpdate(prevProps) {
        this.scrollToContent();
    }

    scrollToContent = () => {
        setTimeout(function() {
            const el = document.getElementById("content");
            el.scrollIntoView({ behavior: 'smooth' , block: 'nearest', inline: 'center'});
        },100); 
    }
    
    render() {
        const components = {
            "norman-kelley": NormanKelly,
            "nicole": NicoleM,
            "recap": Recap,
            "about": About,
        }
        const componentMap = id => components[id];
        const Component = componentMap(this.props.id);

        return (
            <Component />
        )
    }
}

//Normal Kelley Interview
const NormanKelly = () => {
    
    const images = [
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-1.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-2.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-3.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-4.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-5.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-6.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-7.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-8.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-9.jpeg?3028",
        "https://cdn2.shopify.com/s/files/1/0624/0605/files/ispa-nk-10.jpeg?3028"
    ];

    const imgTags = images.map((d,i) => {
        return <Fade bottom distance="50px"><img src={d} alt={"Normal Kelley "+(i+1)} key={d}/></Fade>
    });

    return (
    <div className={styles.interview}>
        <h1>BLURRING AUTHORSHIP WITH NORMAN KELLEY</h1>
        <h2>The Chicago/New Orleans architects will introduce us to modernist furniture designer Enzo Mari at ISPA Sprint One</h2>
        <div className={styles.interview__content}>
            <div className={styles.interview__photos}>
                {imgTags}
            </div>
            <div className={styles.interview__text}>
                {imgTags[0]}
                <p>
                    Carrie Norman and Thomas Kelley are the humblest of multi-hyphenates. Since founding their eponymous architecture and design practice in 2012, the two have finished a wide array of projects in a diverse range of forms: drawings for the American Academy in Rome and the Architecture League of New York; furniture for 2013’s “Wrong Chairs”; essays for <i>Log</i>, <i>MAS Context</i>, <i>Avery Review</i>, and <i>Architect’s Newspaper</i>; and innovative spaces for Aesop and Notre right here in Chicago. Yet the two are more interested in crediting collaborators, such Abigail Chang and Isabelle Reford, discussing influences, and blurring authorship than in touting their numerous achievements.
                </p>
                <p>
                    Norman Kelley’s newest project is a July 20th collaborative workshop with Notre and ISPA, an ongoing special project from Nike Sportswear. The workshop is the first of a two-part series, designed to introduce the ISPA ethos—Improvise, Scavenge, Protect, and Adapt. During the workshop, the Norman Kelley team will lead a group through the construction of one-of-one pieces of furniture using cardboard waste generated by Notre’s daily operations and inspired by Italian modernist artist and furniture designer Enzo Mari’s 1974 instructional manual <i>Autopregettazione</i>.  
                </p>
                <p>
                    And, while architecture and footwear design are their own distinct disciplines, Notre sees Norman Kelley as the perfect collaborator to further the mission of ISPA due to a series of similarities between the two, from their attention to sound structure, to their ability to work creatively within constraints, to their penchant for producing unique objects from preexisting parts. For example, much of ISPA’s waterproof technology is based on reengineered elements from Nike’s ACG line. In addition, Norman Kelley’s collective teaching expertise (Carrie and Thomas are both Assistant Professors of Architecture), encyclopedic knowledge of artists and designers, and past projects in furniture design mean we know their workshop will not only be educational, but will also offer an experimental environment that Notre can’t create on its own.
                </p>
                <p>
                    Jacob Victorine met with Carrie, Thomas, and Abigail in their office located in Chicago’s historic Monadnock Building to discuss <i>Autopregettazione</i>, the tenets of ISPA, and how collaboration affords a form of protection to a practice as small yet prolific as Norman Kelley.
                </p>
                {imgTags[1]}
                <p className={`${styles.notre} ${styles.first}`}>
                    Do you feel like the tenets of ISPA—Improvise, Scavenge, Protect, Adapt—relate in any particular way to what you do at Norman Kelley?
                </p>
                <p className={`${styles.tk} ${styles.first}`}>
                    Absolutely. I think I’m most inspired by the term scavenge. We pride ourselves on being non-inventors and working from historical models that have been successful or not and trying to improve or expand on them.
                </p>
                <p className={`${styles.ac} ${styles.first}`}>
                    Improvise, scavenge, protect, adapt sounds like the role of an architect, so often dealing with constraints that are outside of what you can design and having to adapt to different circumstances.
                </p>
                <p className={`${styles.cn} ${styles.first}`}>
                    I would say we scavenge for starting points.
                </p>
                {imgTags[2]}
                <p className={styles.notre}>
                    A lot of your projects take something that’s preexisting, whether a chair or a space, and then aim to give it a new perspective. I was hoping you could talk more about the connection between Norman Kelley and Enzo Mari and why you chose <i>Autopregettazione</i> as a base text.
                </p>
                <p className={styles.tk}>
                    One reason was the attention to drawing and the idea that drawing is, or can be, a fairly inclusive act. With this project, specifically, Mari was interested in producing drawings that could easily be digested by laypeople and used to produce a designed piece of furniture. Drawing is something that is meant to engage wider audiences and fold them into a discipline that can be fairly purist and can produce barriers. And it’s also economical. It’s a little cliché for a young architecture and design practice to find their voice through an economy of form, an economy of materials, but it is very much a part of our sensibility to find the extraordinary in materials that are less considered, and Mari’s project speaks to that. 
                </p>
                <p className={styles.ac}>
                    I think that the ethos and the process that Mari presents comes from another body of work that we’ve seen through Sol Lewitt, and the way in which these rules or things are given back to people, so it’s less about revering the designer and this final kind of precious built object that they’ve designed. It also relates a bit to the way we discussed the Notre store as giving back this huge space at the very front of the storefront that allows for people to take ownership as they come into the space.
                </p>
                {imgTags[3]}
                <p className={styles.notre}>
                    Would you say choosing the Mari text is very much about accessibility?
                </p>
                <p className={styles.cn}>
                    A little bit. It also reflects our attitude toward authorship. Offering the instruction set for something is really exciting. It blurs the authorship of who’s the maker of this object or artifact and that is something that we tend to explore in all of our projects—a kind of blurry authorship in a way.
                </p>
                <p className={styles.tk}>
                    That’s a great term. Blurry authorship. I really like that. And, most recently, Mari has taken more ownership over this type of project, but one of the fundamental principles of that set of drawings was to confuse it, blur it, and make you quickly able to re-appropriate it. This is pre-IKEA and you were making it yourself. And so the only way of adding your own signature to the assembly of an <i>Autopregettazione</i> object was the alignment of nail heads, but, beyond that, there wouldn’t be too much you could do. Maybe you could paint something or graphically alter it, but that to me speaks to the power of that instruction set, and…
                </p>
                <p className={styles.cn}>
                    The transparency.
                </p>
                <p className={styles.tk}>
                    The transparency, but also the simplicity that would, at the end of the day, allow you to read Mari’s hand in the manual even if he didn’t want you to necessarily. And that I think also relied a lot on quantity because people have reproduced the objects over time, especially “Sedia 1” or “Chair 1.” There are another 19 objects in the set, but that one piece gets made more often than others and you could argue is an icon of modern design.
                </p>
                {imgTags[4]}
                <p className={styles.notre}>
                    The writer Maggie Nelson talks about the idea of her many-gendered mothers — the people who are in her mental/emotional room when she is creating her work. Abby, since you’re one of the two people conducting the Notre x Nike ISPA workshop, are there specific people that you’re planning to bring into the room with you?
                </p>
                <p className={styles.ac}>
                    Everyone has their own process of getting into something; for me, it’s collecting images, and, somehow, that more visceral or immediate visual way of trying to relate things together helps me. Just from initial conversations with Thomas and Carrie about the workshop and this idea of constructing objects from these repurposed or scavenged materials from Notre reminds me of different projects that use minimal materials. Even through, let’s say, ISPA has a catalog of materials, the workshop immediately conjures projects that I’ve seen with zippers, so things I’ve seen in the past or that I’ve studied or my personal interests are somehow brought into it. 
                </p>
                <p className={styles.tk}>
                    Does that conjure up a list of architects or designers who you would feel those involved with the workshop would benefit from knowing more about?
                </p>
                <p className={styles.ac}>
                    Yeah. The kind of initial way that I understood these objects is constructed from paper or things that are maybe seen as fragile or somehow not used for durable and long-lasting timeless pieces. There’s this great project called SHAREyaraicho by Satoko Shinohara that’s in Kagurazaka in Tokyo. And the space is entered through a zipper, which, of course, can only happen in a really safe neighborhood like that. But somehow those kinds of materials and the fragility of things that we’re using to compose more durable things is somehow related. I also have my personal favorite designers like Tokujin Yoshioka who makes these paper chairs that are fragile, yet durable and exquisite in the way they’re composed; I don’t expect that we’ll be producing things like that in the workshop, but, as a reference, they’re in the room.
                </p>
                {imgTags[5]}
                <p className={styles.notre}>
                    Outside of the cardboard furniture that participants are going to walk away with, what else are you hoping people will get out of the workshop?
                </p>
                <p className={styles.tk}>
                    A revelation that, through simple processes and simple structural lessons, one can take seemingly banal materials and translate them into fairly archetypal forms. Hopefully we’ll be able to educate the participants’ eyes in understanding how things stand up in the simplest of terms, but also provide balance with relationship to the ground and then, potentially, offer some kind of comfort, as well. 
                </p>
                <p className={styles.cn}>
                    To this day, I think the coolest part in architecture is the construction site when it’s still in the stage that heightens one’s awareness to the labor of its making. We hope to extend that awareness to the public, so they can look at the construction of a chair and then be able to look at a construction site differently to raise their awareness of an object’s connection to ground and to their body. So, if we’re using stuff as simple as cardboard, the coolest part is when you see a wall that’s been opened and you see just crap materials inside and you understand that the built environment is pretty much composed of accessible materials, right? Like two-by-fours and... 
                </p>
                <p className={styles.tk}>
                    Foam. And paper.
                </p>
                <p className={styles.cn}>
                    Right. Those are pretty everyday objects, so it’s cool to make those linkages. And then, of course, everything gets covered up and the mystery is upheld.
                </p>
                {imgTags[6]}
                <p className={styles.notre}>
                    When many people think of architecture and protection they think of the very obvious: protection from the elements and from other people. What other types of protection can architecture and, specifically, your practice offer?
                </p>
                <p className={styles.tk}>
                    Our practice is really interested in language, and so my question is always, "Define the term?" But I like your reading of it, and I do think that protection suggests a clear separation of either interior/exterior or ownership: yours versus mine. A lot of the work that we’ve done thus far continues within something that is preexisting, so we’re usually protected by another history that we can use as conceptual armor to support either things we are improving on or qualities of the space that we actually can’t change—either because they’re too expensive or it’s not structurally feasible to do so. And those things remain, but we don’t actually have to lay claim to them because they were there before us. So, my interpretation of the way Norman Kelley uses protection is one of working within existing histories. Also, most recently, being able to collaborate with people who have very clear visions in mind about what they do, but maybe not so much about what architecture can do for them. We also like to think that those collaborations afford us a third-party interpretation of what architecture can do or mean. It’s like infiltrating the discipline with a level of naiveté that allows us to unravel it. We’re off the hook a little bit because we’re in partnership with someone who’s not an architect. And their ideas are equally as valid as ours with all of the education that we have, so our protection comes from working with and within others.
                </p>
                <p className={styles.ac}>
                    I like that response a lot.
                </p>
                {imgTags[7]}
                <p className={styles.notre}>
                    Yeah, collaboration can offer both constraint and freedom.
                </p>
                <p className={styles.tk}>
                    Absolutely. We’ll be the first to say that when we began the collaboration with Notre, and on another recent NikeLab collaboration, we were pretty unaware of streetwear culture. And our first lens into it lacked a lot of nuance. Having participated in it now through the collaboration on Notre’s store or the NikeLab space, we’re starting to appreciate that there’s also this democratization of not just fashion, but of all creative disciplines that surround this culture.  
                </p>
                <p className={styles.ac}>
                    When you first said protection, my immediate thinking was about how it’s our duty to protect people from things falling from a building or things crashing on them. But then when Thomas spoke about protection as almost naiveté, I think that not knowing everything about all the things that we’re dabbling in gives us protection to find different approaches. Let’s say, with Notre, maybe not knowing every single detail about which sneaker is the newest one coming out somehow allows us to be new on the scene and see it…
                </p>
                <p className={styles.cn}>
                    More neutral in a way.
                </p>
                <p className={styles.ac}>
                    Yeah, like through a different lens or something.
                </p>
                {imgTags[8]}
                <p className={styles.notre}>
                    When I think of protection, I also think of a talisman. There are functional aspects to the ISPA sneakers, but it’s also a question of how do you feel in what you’re wearing? They’re protecting your feet from walking on the ground in the city, on cement and maybe even broken glass, but do you feel powerful in them? I think that well-designed spaces or pieces of art can also make people feel powerful or make people question, which is also a form of power.
                </p>
                <p className={styles.tk}>
                    That’s right. There could be a whole separate interview on the topic, but the one theme that ties a lot of things together, whether it’s architecture and fashion or, specifically, footwear, is how we all have to operate on, quite literally, the same ground. Architecture, over time, has really obsessed with its relationship to ground. If we think about footwear, its first principle is to consider how the body relates to ground, which is where we, as architects, try to construct narratives around new projects. And, that is the way we can go from being generalists in a lot of different things to exercising expertise when we isolate elements or areas that we have specific and, oftentimes, empirical relationships to. One thing that we try to find with any project is trying to isolate it into its core elements. Abby is really phenomenal at that. I often call her a reductionist; she can very easily read through the mess to appreciate the essence of a space. And, together, Carrie and I have always tried to find ways, like children, to relate every project to something that’s fairly digestible for a wider audience. We look forward with this collaboration to figuring out the common ground where we can find interesting footing. But, also, between Nike, Notre, and Norman Kelley, no one is really a furniture designer, so what’s fun about this project is that we’re in a whole other discipline that has its own set of experts. The one that we’re all going to need to grapple with for this collaboration will be the one whose name is very present, but who will actually be absent.
                </p>
                {imgTags[9]}
            </div>
        </div>
        <ReturnToTop />
    </div>
    )
}

const About = () => {
    return (
        <div className={styles.about}>
            <div className={styles.header}>
                <div className={styles.address}>
                    <h3>Notre x Nike ISPA</h3>
                    <h3>118 N Peoria St.</h3>
                    <h3>Chicago, IL</h3>
                    <h3>60607 USA</h3>
                </div>
                <div className={styles.logo}>
                    <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Logo.svg?3013" alt="ISPA Logo"/>
                </div>
            </div>
            <div className={styles.subject}>
                <h4>NTR-NK-WKSP-SP1</h4>
                <h4>SUBJECT: Program Overview and Workshop Information</h4>
            </div>
            <div className={styles.body}>
                <p>Notre and Nike present: ISPA Sprint 1. This is a two-day workshop series aimed at creating space for experimentation and skill-sharing. Our mentors — architects <a href="http://www.normankelley.us">Norman Kelley</a> and designer <a href="http://www.nicolemclaughlin.net">Nicole McLaughlin</a> — will lead participants through zero-waste creation exercises where they will upcycle materials typically considered disposable into objects that can be used indefinitely. The goal is to reframe the way we think about waste while picking up some useful techniques for future projects.</p>
                <p>
                    <h5>WHAT IS ISPA?</h5>
                    Nike’s ISPA program is a philosophy — a way of approaching rapidly changing, unpredictable environments. ISPA manifests itself mainly as sneakers and apparel, but above all else, it is a set of principles:
                </p>
                <div className={styles.ispaDesc}>
                    <div>
                        <h5>IMPROVISE</h5>
                        <p>Never be blocked by the first answer to a problem, IMPROVISE to see if it can be improved.</p>
                    </div>
                    <div>
                        <h5>SCAVENGE</h5>
                        <p>To find the materials you need, SCAVENGE and pull together the best available options to solve the problem.</p>
                    </div>
                    <div>
                        <h5>PROTECT</h5>
                        <p>Your solution must PROTECT against the problem.</p>
                    </div>
                    <div>
                        <h5>ADAPT</h5>
                        <p>ADAPT all solutions to fit their broadest potential.</p>
                    </div>
                </div>
                <p>These principles can be applied to problems of all sorts, not just product design. According to Nike, “ISPA is a belief that, with a little inspiration, any problem can be solved with the materials at hand.” Materials can be both physical and conceptual — a fashion designer might scavenge for deadstock fabrics, while an architect might scavenge for ideas. ISPA is an optimistic framework for an uncertain world.</p>
            </div>
            <img className={styles.ispaSwoosh} src="https://cdn.shopify.com/s/files/1/0624/0605/files/ispaswoosh8.svg?3015" alt="ISPA Swoosh"></img>
            <ReturnToTop />
        </div>
    )
}

export default Interview;