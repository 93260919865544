import React from 'react';
import styles from './Recap.module.css';
import pdf from '../assets/NIKE X NOTRE_MANUAL FINAL_190719.pdf';

class Recap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        const recapProps = {
            slides: [
                <p>DEBRIEF: NORMAN KELLEY
                    <br/>7.20.19
                    <br/>
                </p>,
                <p className={styles.summary}>The first day of ISPA Sprint One featured the Norman Kelley team leading workshop participants through the construction of cardboard furniture inspired by the work of Italian modernist designer Enzo Mari. The cardboard sourced for the production of these pieces were sourced from Notre and Nike’s daily operations, in an effort to ensure these were zero-waste projects.</p>, 
                <p className={styles.summary}>Particpants received toolkits full of the implements necessary to take on similar projects at home. In the spirit of Mari’s <i>Autogettazione</i>—a collection of 19 blueprints for furniture that could be produced of cheap, easily-sourced materials and built without prior experience—we have made the manual Norman Kelley created available for download so you can create your own versions. Post your own creations on Instagram and tag Notre so we can share them! </p>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-2-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-34-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-31-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-61-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-17-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-21-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-59-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-47-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-16-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-63-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-29-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-7-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-10-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-64-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-55-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-14-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-23-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-15-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-38-nk.jpg?3630" alt="Norman Kelley Workshop"/>,
                <p>DEBRIEF: NICOLE MCLAUGHLIN<br/>7.21.19</p>,
                <p className={styles.summary}>The second day of ISPA Sprint One featured designer Nicole McLaughlin sharing her upcycling process with workshop participants. Using materials sourced from Found Indiana Vintage, participants built their own one-of-one footwear prototypes using the style of bricolage McLaughlin showcases on her Instagram. Participants received toolkits full of gear curated by McLaughlin that they can use to continue their own creative practices. We hope these photos inspire you to take on your own zero-waste projects. </p>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-37-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-49-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-15-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-69-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-77-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-55-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-30-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-60-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-44-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-68-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-33-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-61-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-11-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-73-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-58-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-72-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-53-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-59-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-10-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-57-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-16-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-66-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>,
                <img src="https://cdn.shopify.com/s/files/1/0624/0605/files/ISPA_Build_out_-70-nm.jpg?3630" alt="Nicole McLaughlin Workshop"/>

            ],
            date: '7.20.19',
            title: 'Norman Kelley',
            id: "nk-recap",
            nkIndex: 0,
            nmIndex: 23,
            pdf: <a className={styles.pdf} href={pdf} >DOWNLOAD MANUAL</a>

        }

        return (
            <div className={styles.recap}>
                <Gallery {...recapProps}/>
            </div>
        )
    }
}

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSlide: 0,
            activeWorkshop: "nk"
        }
    }

    componentDidMount() {
        document.addEventListener("keydown",this.handleKeyPress);

    }

    goNextSlide = (n) => {
        const nextSlide = this.mod(this.state.selectedSlide + n, this.props.slides.length);
        const activeWorkshop = nextSlide >= this.props.nmIndex ? "nm" : "nk"; 

        this.setState({selectedSlide: nextSlide, activeWorkshop: activeWorkshop});
    }

    goToSlide = (i) => {
        this.setState({selectedSlide: i});
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 39) this.goNextSlide(1);
        if (event.keyCode === 37) this.goNextSlide(-1);
    }

    toElement = (id) => {
        setTimeout(function() {
            const el = document.getElementById(id);
            el.scrollIntoView({ behavior: 'smooth' , block: 'nearest', inline: 'center'});
        },100); 
    }

    mod = (n,m) => { 
        return ((n % m) + m) % m
    }

    lazyify = (src) => {
        const cropString = "_x1000_crop_center.jpg";
        const split = src.split('.jpg');
        return split[0] + cropString + split[1];
    }

    render() {
        const buttonStyle = {color:"#F16729"};
        const slideProp = this.props.slides[this.state.selectedSlide];

        let slide;
        if (slideProp.type === "p") {
            slide = slideProp;
        } else {
            slide = 

            <img
                alt={slideProp.props.alt}
                src={this.lazyify(slideProp.props.src)}
            />
        }
    
        return (
            <div id={this.props.id}>
                <div className={styles.gallery} >
                    {slide}
                </div>
                <div className={styles.galleryOverlay}>
                    <div className={styles.left} onClick={() => this.goNextSlide(-1)}/>
                    <div className={styles.right} onClick={() => this.goNextSlide(1)}/>
                </div>
                {this.state.activeWorkshop === "nk" && this.props.pdf}
                <Counter current={this.state.selectedSlide + 1} total={this.props.slides.length}/>
                <div className={styles.buttons}>
                    <div className={styles.gotoButton} 
                        style={this.state.activeWorkshop === "nk" ? buttonStyle : {}}
                        onClick={() => {
                            this.setState({activeWorkshop:"nk"})
                            this.goToSlide(this.props.nkIndex)
                         }}>
                        NORMAN KELLEY
                    </div>
                    <div className={styles.gotoButton}
                        style={this.state.activeWorkshop === "nm" ? buttonStyle :{}} 
                        onClick={() => {
                            this.setState({activeWorkshop:"nm"})
                            this.goToSlide(this.props.nmIndex)
                         }}>
                        NICOLE MCLAUGHLIN
                    </div>
                </div>
            </div>
        )
    }
}

const Title = (props) => {
    const name = props.title.toUpperCase();
    return (
        <div className={styles.title}>
            <p>> DEBRIEF: {props.date}</p>
            <p>> MENTOR: {name}</p>
        </div>
    )
}

const Counter = (props) => {
    return (
        <div className={styles.counter}>
            <p>{`${props.current}/${props.total}`}</p>
        </div>
    )
}

export default Recap;