import React from 'react';
import Nav from './components/Nav';
import Interview from './components/Interview';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: ""
    };
  }

  updateSelected = (e) => {
    this.setState({selected:e});
  }
  
  render() {
    return (
      <div className="App">
        <Nav updateSelected={this.updateSelected}/>
        { this.state.selected.length > 0 && this.state.selected !== "sign-up" &&
          <div id="content">
            <Interview id={this.state.selected}/> 
          </div>
        }
      </div>
    );
  }
}

export default App;
